import React from "react";
import verify from '../images/verify.png'
import { Link } from "react-router-dom";

const Verify = () => {
    return (
        <div className="w-full mx-auto">
            <div className="grid gid-cols-1 md:grid-cols-2">
                <div>
                    <img className="h-screen w-full" src={verify} alt="ALS" />
                </div>
                <div className="flex flex-col justify-center items-start m-10 text-left">
                    <Link to="/login"><h1 className="text-lg text-black my-5">&lt; Back to Login</h1></Link>

                    <h1 className="text-3xl text-black font-bold my-5">Verify Code</h1>
                    <h1 className="text-md text-gray-400">An authentication code has been sent to your email.</h1>
                    <div class="flex flex-col mt-10 w-3/4">

                        <div class="relative my-5">
                            <label
                                for="code"
                                class="absolute -top-2.5 left-3 bg-white px-1 text-sm text-black"
                            >
                                Enter Code
                            </label>
                            <input
                                type="text"
                                id="code"
                                value="7789BM6X"
                                class="w-full border border-gray-300 rounded-md px-3 py-3 text-black focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                            />
                        </div>

                        <div>
                            <h1 className="text-md text-black">Didn&apos;t receive a code? <span className="text-red-500 ml-2">Resend</span></h1>
                        </div>

                    </div>

                    <div className="mt-5 w-full">
                        <button className="bg-red-600 w-3/4 text-lg text-white p-5 rounded-xl">Verify</button>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Verify;