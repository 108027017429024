import React, { useEffect, useState } from "react";
import Chart from "../components/Chart";
import axios from "axios";
import SuperAgentData from "../components/SuperAgentData";
import KioskData from "../components/KioskData";

const Sales = () => {

    const [totalRevenue, setTotalRevenue] = useState(0);
    const [revenueIncrease, setRevenueIncrease] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [salesIncrease, setSalesIncrease] = useState(0);

    const [superagents, setSuperagents] = useState(0);
    const [kioksowners, setKioskowners] = useState(0);

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    useEffect(() => {

        const calculateStats = (salesData) => {
            const now = new Date();
            const currentMonth = now.getMonth(); // 0-indexed: December is 11
            const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
            const currentYear = now.getFullYear();
            const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

            let totalRevenueToDate = 0;
            let currentMonthRevenue = 0;
            let lastMonthRevenue = 0;

            let totalSalesToDate = 0;
            let currentMonthSales = 0;
            let lastMonthSales = 0;

            salesData.forEach((sale) => {
                const saleDate = new Date(sale.salesDate);
                const saleMonth = saleDate.getMonth();
                const saleYear = saleDate.getFullYear();

                // Accumulate total revenue and sales to date
                totalRevenueToDate += sale.totalSalesValue;
                totalSalesToDate += sale.quantity;

                // Accumulate revenue and sales for current month
                if (saleMonth === currentMonth && saleYear === currentYear) {
                    currentMonthRevenue += sale.totalSalesValue;
                    currentMonthSales += sale.quantity;
                }

                // Accumulate revenue and sales for last month
                if (saleMonth === lastMonth && saleYear === lastMonthYear) {
                    lastMonthRevenue += sale.totalSalesValue;
                    lastMonthSales += sale.quantity;
                }
            });

            // Calculate percentage increases
            const revenueIncreasePercentage =
                lastMonthRevenue > 0
                    ? ((currentMonthRevenue - lastMonthRevenue) / lastMonthRevenue) * 100
                    : 0;

            const salesIncreasePercentage =
                lastMonthSales > 0
                    ? ((currentMonthSales - lastMonthSales) / lastMonthSales) * 100
                    : 0;

            // Update state
            setTotalRevenue(totalRevenueToDate);
            setRevenueIncrease(revenueIncreasePercentage.toFixed(2));
            setTotalSales(totalSalesToDate);
            setSalesIncrease(salesIncreasePercentage.toFixed(2));
        };

        const getSales = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL+"api/sales/get-sale", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                if (res.data.success) {
                    calculateStats(res.data.sales);
                }


            } catch (err) {
                if (err.response.status === 400) {
                    alert(err.response.data);
                }
            }
        }

        getSales();

        const activeUsers = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL+"api/user/get-user", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });
        
                
                setSuperagents((res.data.users.filter(user => user.isSuperAgent === 1)).length);
                setKioskowners((res.data.users.filter(user => user.referredSuperAgent)).length);
            } catch (err) {
                if (err.response?.status === 400) {
                    alert(err.response.data);
                } else {
                    console.error("Error in getUsers:", err);
                }
            }
        }
        activeUsers();
    }, []);
    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                <div className="flex justify-between">
                    <h1 className="text-4xl text-black font-bold">Sales</h1>

                    <div className="flex">
                        <div id="date-range-picker" date-rangepicker="" className="flex items-center">
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg
                                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                    </svg>
                                </div>
                                <input
                                    id="datepicker-range-start"
                                    name="start"
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Select date start"
                                />
                            </div>
                            <span className="mx-4 text-gray-500">to</span>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg
                                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                    </svg>
                                </div>
                                <input
                                    id="datepicker-range-end"
                                    name="end"
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Select date end"
                                />
                            </div>
                        </div>

                        <button className="bg-black text-white px-3 py-1 ml-2 rounded-full">Download</button>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-5">
                    <div className="md:col-span-1 bg-white border text-left border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex flex-col m-4 md:m-0">
                            <div className="flex justify-between">
                                <h1 className="text-black  text-lg">Total Revenue</h1>
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2" />
                                </svg>
                            </div>
                            <h1 className="text-xl text-black font-extrabold">₦{totalRevenue}</h1>
                            <h1 className="text-lg text-gray-500">+{revenueIncrease}% from last month</h1>
                        </div>
                    </div>

                    <div className="md:col-span-1 bg-white border text-left border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex flex-col m-4 md:m-0">
                            <div className="flex justify-between">
                                <h1 className="text-black text-lg">Active Kiosk Owners</h1>
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>

                            </div>
                            <h1 className="text-xl text-black font-extrabold">+{kioksowners}</h1>
                            <h1 className="text-lg text-gray-500">+20.9% from last month</h1>
                        </div>
                    </div>

                    <div className="md:col-span-1 bg-white border text-left border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex flex-col m-4 md:m-0">
                            <div className="flex justify-between">
                                <h1 className="text-black text-lg">Active Super Agents</h1>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
                                </svg>

                            </div>
                            <h1 className="text-xl text-black font-extrabold">+{superagents}</h1>
                            <h1 className="text-lg text-gray-500">+20.9% from last month</h1>
                        </div>
                    </div>

                    <div className="md:col-span-1 bg-white border text-left border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex flex-col m-4 md:m-0">
                            <div className="flex justify-between">
                                <h1 className="text-black text-lg">Sales</h1>
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path fill-rule="evenodd" d="M4 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z" clip-rule="evenodd" />
                                    <path fill-rule="evenodd" d="M5 14a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z" clip-rule="evenodd" />
                                </svg>

                            </div>
                            <h1 className="text-xl text-black font-extrabold">+{totalSales}</h1>
                            <h1 className="text-lg text-gray-500">+{salesIncrease}% from last month</h1>
                        </div>
                    </div>


                    {/* Super Agents Section */}
                    <div className="md:col-span-2">
                        <SuperAgentData isKioskSales={false}/>

                    </div>

                    {/* Kiosk Sales Section */}
                    <div className="md:col-span-2">
                        <KioskData />
                    </div>


                    {/* graph section */}
                    <div className="md:col-span-4 bg-white rounded-lg flex flex-col">
                        <h1 className="text-lg text-left font-bold m-4 flex" >Sales
                            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>

                        </h1>
                        <div className=" w-full bg-white rounded-lg shadow dark:bg-gray-800">

                            <Chart />

                        </div>


                    </div>

                </div>


            </div>
        </div>
    )
}

export default Sales;