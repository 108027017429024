import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "flowbite";

const Admin = () => {

    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [designation, setDesignation] = useState('');
    const [password, setPassword] = useState('');
    const [reporting, setReporting] = useState('');
    const [refreshKey, setRefreshKey] = useState(0);
    const [result, setResult] = useState(null);

    const [permissionRole, setPermissionRole] = useState('');
    const [roles, setRoles] = useState([]);


    const [id, setId] = useState('');




    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const [admins, setAdmins] = useState([]);

    // Get all admins data
    useEffect(() => {
        const getAdmin = async (e) => {

            try {
                const res = await axios.get(process.env.REACT_APP_API_URL+"api/admin-users/get-admin-user", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setAdmins(res.data.adminUsers);


            } catch (err) {
                if (err.response.status === 400) {
                    alert(err.response.data);
                }
            }
        }
        getAdmin();

        const getRoles = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL+"api/role/get-role", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setRoles(res.data.roles);


            } catch (err) {
                if (err.response.status === 400) {
                    alert(err.response.data);
                }
            }
        }
        getRoles();
    }, [refreshKey]);


    // create admin
    const handleCreateAdmin = async (e) => {
        e.preventDefault();

        const getManager = async (searchName) => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL+"api/admin-users/get-admin-user", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                const user = res.data.adminUsers.find((user) => user.name.toLowerCase() === searchName.toLowerCase());
                if (user) {
                    setResult({ name: user.name, id: user.id });
                } else {
                    setResult(null);
                }


            } catch (err) {
                if (err.response.status === 400) {
                    alert(err.response.data);
                }
            }
        }

        getManager(reporting);



        const postData = {
            "name": fullname,
            "email": email,
            "password": password,
            "designation": designation,
            "phone": "123-456-7890",
            "roleId": permissionRole,
            "reportingTo": result.id,
            "status": true,
        };

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL+"api/admin-users/create-admin-user", postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            setRefreshKey((prevKey) => prevKey + 1);
            alert(res.data.message);


        } catch (err) {
            if (err.response.status === 400) {
                alert(err.response.data);
            }
        }

    };


    // format date for table
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };

    useEffect(() => {
        // Initialize the modal on component mount
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.hide(); // Ensure the modal is initially hidden
        }

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    // get single admin data
    const getAdminData = async (id) => {
        const modalElement = document.getElementById("edit-modal");
        if (modalElement) {
            const modal = new Modal(modalElement);
            modal.show();
        }

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL+`api/admin-users/get-admin-user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            const getManager = async (id) => {
                try {
                    const res = await axios.get(process.env.REACT_APP_API_URL+`api/admin-users/get-admin-user/${id}`, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${token}`,
                            "auth_token": `${auth_token}`,
                        },
                    });



                    setReporting(res.data.adminUser.name);



                } catch (err) {
                    console.log(err);

                }
            }

            getManager(res.data.adminUser.reportingTo);

            setPermissionRole(res.data.adminUser.roleId);

            setFullname(res.data.adminUser.name);
            setEmail(res.data.adminUser.email);
            setDesignation(res.data.adminUser.designation);
            setId(res.data.adminUser.id)


        } catch (err) {
            if (err.response.status === 400) {
                alert(err.response.data);
            }
        }
    }


    // update single admin data
    const handleUpdateAdmin = async (e) => {
        e.preventDefault();
        const update_id = id;

        const getManager = async (searchName) => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_URL+"api/admin-users/get-admin-user", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                const user = res.data.adminUsers.find((user) => user.name.toLowerCase() === searchName.toLowerCase());
                if (user) {
                    setResult({ name: user.name, id: user.id });
                } else {
                    setResult(null);
                }


            } catch (err) {
                if (err.response.status === 400) {
                    alert(err.response.data);
                }
            }
        }

        getManager(reporting);
        console.log(result);

        const postData = {
            "name": fullname,
            "email": email,
            "designation": designation,
            "reportingTo": result.id,
            "roleId": permissionRole,
        };

        try {

            const res = await axios.put(process.env.REACT_APP_API_URL+`api/admin-users/update-admin-user/${update_id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            alert(res.data.message);


        } catch (err) {
            if (err.response.status === 400) {
                alert(err.response.data);
            }
        }

    };

    const deleteUser = async (id) => {

        try {
            const res = await axios.delete(process.env.REACT_APP_API_URL+`/api/admin-users/delete-admin-user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            setRefreshKey((prevKey) => prevKey + 1);

            alert(res.data.message);



        } catch (err) {
            console.error("Error in getSales:", err);
        }
    }


    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                <ul className="flex md:w-1/2 mt-5 text-md text-black bg-[#F1F5F9]  rounded-lg overflow-hidden shadow-sm">
                    <li className="flex-1">
                        <a
                            href="/admins"
                            className="block m-1 p-2 text-center  bg-white text-black hover:text-black"
                            aria-current="page"
                        >
                            Admins
                        </a>
                    </li>
                    <li className="flex-1">
                        <a
                            href="/roles"
                            className="block m-1 p-2 text-md text-center text-gray-500 rounded-lg shadow-sm"

                        >
                            Manage Roles
                        </a>
                    </li>
                </ul>

                <div className="mt-5 w-1/2">
                    <button type="button" data-collapse-toggle="navbar-search" aria-controls="navbar-search" aria-expanded="false" class="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1">
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                        <span class="sr-only">Search admins</span>
                    </button>
                    <div class="relative hidden md:block">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span class="sr-only">Search icon</span>
                        </div>
                        <input type="text" id="search-navbar" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Admins" />
                    </div>
                </div>

                <div className="flex justify-start">

                    <button type="button" 
                    onClick={() => {
                        const createElement = document.getElementById("popup-modal");
                        if (createElement) {
                            const create_modal = new Modal(createElement);
                            create_modal.show();
                        }
                    }}
                     className="flex text-white bg-black rounded-full px-5 py-2 my-5">Create new admin</button>



                </div>

                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Admins</h1>

                        </div>

                    </div>

                    <div className="relative overflow-x-auto  sm:rounded-lg mt-5">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Date & Time added
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Permission
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3">

                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    admins.map((admin) => (
                                        <tr key={admin.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                {admin.name}
                                            </th>
                                            <td className="px-6 py-4">{formatDate(admin.createdAt)}</td>
                                            <td className="px-6 py-4">4 Permissions</td>


                                            <td className="px-6 py-4">
                                                {admin.status === true ? <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-3/4 text-center">Active</p> : <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-1/2 text-center">Inactive</p>}
                                            </td>
                                            <td className="px-6 py-4">
                                                <button type="button" onClick={() => getAdminData(admin.id)} className="text-white py-1 px-4 bg-black rounded-full">Edit</button>
                                            </td>

                                        </tr>
                                    ))
                                }


                            </tbody>
                        </table>
                    </div>

                    <div
                        id="popup-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const createElement = document.getElementById("popup-modal");
                                        if (createElement) {
                                            const create_modal = new Modal(createElement);
                                            create_modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Create new admin</h2>
                                        <form onSubmit={handleCreateAdmin}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Enter Full Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="David"
                                                        value={fullname}
                                                        onChange={(e) => setFullname(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Company Email ID</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="email"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="UX@crowdafrik.com"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required

                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Designation</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Product Designer"
                                                        value={designation}
                                                        onChange={(e) => setDesignation(e.target.value)}
                                                        required
                                                    />
                                                </div>


                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Reporting to</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Sreejit Sivankutty"
                                                        value={reporting}
                                                        onChange={(e) => setReporting(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Password</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="*********"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                            </div>

                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-[80%] text-left mt-10">
                                                <div>
                                                    <label className="block mb-1 text-gray-500 text-md">Allow permission to add super agents</label>
                                                </div>
                                                <div>
                                                    <select
                                                        id="batchId"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={permissionRole}
                                                        onChange={(e) => setPermissionRole(e.target.value)}
                                                        required
                                                    >
                                                        <option value="">--Select a Role--</option>
                                                        {
                                                            roles.map((role) => (
                                                                role.roleName === "mobile user" ? '' :
                                                                    <option key={role.id} value={role.id}>{role.roleName}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>


                                            </div>
                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Create
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const createElement = document.getElementById("popup-modal");
                                                            if (createElement) {
                                                                const create_modal = new Modal(createElement);
                                                                create_modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    {/* edit modal */}
                    <div
                        id="edit-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const modalElement = document.getElementById("edit-modal");
                                        if (modalElement) {
                                            const modal = new Modal(modalElement);
                                            modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Edit admin</h2>
                                        <form onSubmit={handleUpdateAdmin}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Enter Full Name</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="David"
                                                        value={fullname}
                                                        onChange={(e) => setFullname(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Company Email ID</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="UX@crowdafrik.com"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Designation</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Product Designer"
                                                        value={designation}
                                                        onChange={(e) => setDesignation(e.target.value)}
                                                        required
                                                    />
                                                </div>


                                                <div className="md:col-span-1">
                                                    <label className="block mb-1 font-medium">Reporting to</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <input
                                                        type="text"
                                                        className="w-full mb-1 p-2 border border-gray-300 rounded"
                                                        placeholder="Sreejit Sivankutty"
                                                        value={reporting}
                                                        onChange={(e) => setReporting(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                            </div>

                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-[80%] text-left mt-10">
                                                <div>
                                                    <label className="block mb-1 text-gray-500 text-md">Allow permission to add super agents</label>
                                                </div>
                                                <div>
                                                    <select
                                                        id="batchId"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={permissionRole}
                                                        onChange={(e) => setPermissionRole(e.target.value)}
                                                        required
                                                    >
                                                        <option value="">--Select a Role--</option>
                                                        {
                                                            roles.map((role) => (
                                                                role.roleName === "mobile user" ? '' :
                                                                    <option key={role.id} value={role.id}>{role.roleName}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>


                                            </div>
                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const modalElement = document.getElementById("edit-modal");
                                                            if (modalElement) {
                                                                const modal = new Modal(modalElement);
                                                                modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="flex justify-start ">
                                            <button onClick={() => deleteUser(id)} className="bg-red-600 text-white px-5 py-2 rounded-full">Remove</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Admin;