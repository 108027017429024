import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Chart = () => {

  const location = useLocation();
  const token = sessionStorage.getItem("bearer_token");
  const auth_token = sessionStorage.getItem("token");
  const [apiData,setApiData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const getSalesData = async () => {
    try {
      
      const res = await axios.get(process.env.REACT_APP_API_URL+'api/sales/get-sale', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "auth_token": `${auth_token}`,
        },
      });


      if(location.pathname === "/"){
        setApiData(res.data.sales);
      }
      else if(location.pathname === "/sales"){
        const superagent_sales = res.data.sales.filter(sale => sale.agentId === null );
        setApiData(superagent_sales);
      }
      else{
        const kioskowners_sale = res.data.sales.filter(sale => sale.agentId !== null );
        setApiData(kioskowners_sale);
      }

      setIsDataFetched(true);
    } catch (err) {
      console.error("Error fetching data:", err);
      // alert("Data not found");
    }
  }


  // Function to get the past 7 days
  const getPast7Days = () => {
    const dates = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push(date);
    }
    return dates;
  };

  const past7Days = getPast7Days();

  // Formatting data for the graph
  const formattedCategories = past7Days.map((date) =>
    date.toLocaleDateString("en-US", { day: "2-digit", month: "short" })
  );

  const salesData = past7Days.map((date) => {
    const dateString = date.toISOString().split("T")[0];
    const sales = apiData
      .filter((item) => item.salesDate.startsWith(dateString))
      .reduce((acc, curr) => acc + curr.totalSalesValue, 0);
    return sales;
  });
  useEffect(() => {
    getSalesData();
    // Setting chart options
    const options = {
      series: [
        {
          name: "Revenue",
          data: salesData,
          color: "#1A56DB",
        },
      ],
      xaxis: {
        show: true,
        categories: formattedCategories,
        labels: {
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
        min: 0,
        max: 540000,
        tickAmount: 6,
        labels: {
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400',
          },
          formatter: function (value) {
            return value / 1000 + 'K';
          },
        },
      },

      chart: {
        height: "400",
        width: "100%",
        type: "area",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        followCursor: true,
        theme: "light",
        x: {
          show: true,
          format: "dd MMM, yyyy",
        },
        y: {
          formatter: function (value) {
            return `₦${value / 1000}k`;
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0,
          shade: "light",
          gradientToColors: ["#1C64F2"],
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        curve: "smooth",
      },
      legend: {
        show: false,
      },
      grid: {
        show: true,
        borderColor: "#E5E7EB",
        strokeDashArray: 5,
        position: "back",
      },
    };

    



    // Ensure the chart is only initialized if the DOM is ready

    const chartElement = document.getElementById("labels-chart");
    if (chartElement) {
      const chart = new ApexCharts(chartElement, options);
      chart.render();


      // Clean up the chart when the component unmounts
      return () => {
        chart.destroy();
      };
    }
  }, [isDataFetched]); // Empty dependency array ensures this runs only once after the initial render

  return <div id="labels-chart"></div>;
};


export default Chart;