import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const SuperAgentData = ({isKioskSales}) => {
    const location = useLocation();
    const isDashboard = location.pathname === "/";

    const [superAgents, setSuperagents] = useState([]);
    const [isUsersFetched, setIsUsersFetched] = useState(false); // Track if users have been fetched

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");

    const getUsers = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + "api/user/get-user", {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            const filteredUsers = res.data.users.filter(user => isKioskSales? user.isSuperAgent !== 1 || user.referredSuperAgent === null : user.isSuperAgent === 1);

            setSuperagents(filteredUsers);

            setIsUsersFetched(true); // Mark users as fetched
        } catch (err) {
            console.error("Error in getUsers:", err);
        }
    };

    const getSales = async () => {
        if (!isUsersFetched) return; // Ensure users are fetched before proceeding

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + "api/sales/get-sale", {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            const sales = res.data.sales;

            const updatedSuperAgents = superAgents.map(agent => {
                const agentSales = sales.filter(sale => sale.userId === agent.id );
                const totalSalesValue = agentSales.reduce(
                    (acc, sale) => acc + sale.totalSalesValue,
                    agent.totalSalesValue || 0
                );

                return { ...agent, totalSalesValue };
            });

            setSuperagents(updatedSuperAgents);
        } catch (err) {
            console.error("Error in getSales:", err);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getUsers();
            } catch (err) {
                console.error("Error in fetchData (getUsers):", err);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures this runs once on mount

    useEffect(() => {
        const fetchSalesData = async () => {
            try {
                await getSales();
            } catch (err) {
                console.error("Error in fetchSalesData:", err);
            }
        };

        if (isUsersFetched) {
            fetchSalesData();
        }
    }, [isUsersFetched]); // Runs when `isUsersFetched` changes // Depend on superAgents to re-run getSales() when it changes



    return (
        <div className="w-full h-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex items-center justify-between mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    {isKioskSales ? 'Kiosk Owners' : 'Super Agents'}
                </h5>
                {isDashboard ?
                    <a
                        href="/add-superagent"
                        className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                    >
                        Add a Super Agent
                    </a> :
                    <div>
                        <button type="button" data-collapse-toggle="navbar-search" aria-controls="navbar-search" aria-expanded="false" class="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1">
                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span class="sr-only">Search</span>
                        </button>
                        <div class="relative hidden md:block">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                                <span class="sr-only">Search icon</span>
                            </div>
                            <input type="text" id="search-navbar" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." />
                        </div>
                    </div>
                }

            </div>
            <div className="flow-root">
                <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">

                    {
                        superAgents.slice(0, 5).map((superagent) => (


                            <li key={superagent.id} className="py-3 sm:py-4">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="w-8 h-8 rounded-full"
                                            src="https://flowbite.com/docs/images/people/profile-picture-1.jpg"
                                            alt="Neil image"
                                        />
                                    </div>
                                    <div className="flex-1  min-w-0 ms-4 text-left">
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                            {superagent.name}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                            {superagent.email}
                                        </p>
                                    </div>
                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                        ₦{superagent.totalSalesValue ? superagent.totalSalesValue : 0}

                                        {!isDashboard ? <a href={"/agent-profile/" + superagent.id}><p className="text-blue-600 text-md ml-3 underline">View</p></a> : ''}
                                    </div>
                                </div>
                            </li>
                        ))
                    }



                </ul>
            </div>
            {isDashboard ?
                <div className="flex justify-center mt-5">
                    <a href="/sales">
                        <p className="text-md text-blue-400">View All</p>
                    </a>
                </div> : ''}
        </div>
    );
}

export default SuperAgentData;
