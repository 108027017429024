import React from "react";

import Batch from "../components/Batch";




const Track = () => {


    return (
        <div className="bg-gray-100">
            <Batch />
        </div>
    )
}

export default Track;