import React, { useState } from "react";
import logo from '../images/als_logo.svg'
import login from '../images/login.png'
import axios from "axios";
import { useNavigate } from "react-router-dom";
// const SimpleCrypto = require("simple-crypto-js").default;
// const HashCat = require("../config/hashCat.json");
// const secretKey = HashCat.ADMIN_TOKEN.HASH_LOGIN_TOKEN;
// const simpleCrypto = new SimpleCrypto(secretKey);
// const CryptoTS = require("crypto-ts");
import SimpleCrypto from "simple-crypto-js";
import { AES, enc } from "crypto-ts";
import HashCat from "../config/hashCat.json";

// const secretKey = HashCat.ADMIN_TOKEN.HASH_LOGIN_TOKEN;
// const simpleCrypto = new SimpleCrypto(secretKey);


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        const postData = {
            "email": username,
            "password": password,
        };

        try {
            const res = await axios.post(process.env.REACT_APP_API_URL+"api/admin/login", postData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            // console.log(res.data);
            const user_data = res.data.userData;

            // const bytes = CryptoTS.AES.decrypt(
            //     user_data,
            //     HashCat.ADMIN_TOKEN.HASH_LOGIN_AES
            // );
            // const plaintext = bytes.toString(CryptoTS.enc.Utf8);
            // let decipherTextsecure = simpleCrypto.decrypt(plaintext);
            // console.log("this is decipherTextsecure", decipherTextsecure);

            const bytes = AES.decrypt(user_data, HashCat.ADMIN_TOKEN.HASH_LOGIN_AES);

            // Step 2: Convert decrypted bytes to UTF-8 string
            const plaintext = bytes.toString(enc.Utf8);

            // Step 3: Initialize SimpleCrypto with the secret key
            const simpleCrypto = new SimpleCrypto(HashCat.ADMIN_TOKEN.HASH_LOGIN_TOKEN);

            // Step 4: Decrypt the plaintext using SimpleCrypto
            const decipherTextsecure = simpleCrypto.decrypt(plaintext);

            console.log("Decrypted Data:", decipherTextsecure);

            sessionStorage.setItem("user_data", JSON.stringify(decipherTextsecure));
            
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("bearer_token", res.data.bearerToken);


            navigate('/');

        } catch (err) {
            if (err.response.status === 400) {
                alert(err.response.data);
            }
        }

    };

    return (
        <div className="w-full mx-auto">
            <img src={logo} alt="ALS" className="fixed m-5" />
            <div className="grid gid-cols-1 md:grid-cols-2">
                <div>
                    <img className="h-screen w-full" src={login} alt="ALS" />
                </div>
                <div className="flex flex-col justify-center items-start m-10 text-left">
                    <h1 className="text-3xl text-black font-bold my-5">Login</h1>
                    <h1 className="text-md text-gray-400">Login to access your ALS dashboard account</h1>

                    <form className="flex flex-col mt-10 w-3/4" onSubmit={handleLogin}>


                        <div className="">

                            <div className="relative my-5">
                                <label
                                    for="email"
                                    className="absolute -top-2.5 left-3 bg-white px-1 text-sm text-black"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="john.doe@gmail.com"
                                    className="w-full border border-gray-300 rounded-md px-3 py-3 text-black focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>


                            <div className="relative my-5">
                                <label
                                    for="password"
                                    className="absolute -top-2.5 left-3 bg-white px-1 text-sm text-black"
                                >
                                    Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    className="w-full border border-gray-300 rounded-md px-3 py-3 text-black focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                                    placeholder="*******"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />

                            </div>
                        </div>

                        <div className="mt-5 w-full">
                            <button className="bg-red-600 w-3/4 text-lg text-white p-5 rounded-xl" type="submit">Login</button>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    )
}

export default Login;