import React from "react";
import PrizeClaim from "../components/PrizeClaim";

const Claims = () => {

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                <PrizeClaim />
            </div>
        </div>
    )
}

export default Claims;