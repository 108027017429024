import React, { useEffect, useState } from "react";
import axios from "axios";

const Searchcard = () => {

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");
    const [scratchcards, setScratchCards] = useState([]);

    useEffect(() => {
        const getScratchCards = async () => {

            try {
                const res = await axios.get(process.env.REACT_APP_API_URL+"api/scratchcard/get-scratchcard", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                setScratchCards(res.data.scratchcards);
            }
            catch (err) {
                if (err.response.status === 400) {
                    alert(err.response.data);
                }
            }
        }
        getScratchCards();
    }, []);

    // format date for table
    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };


    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                

                <div className="mt-5 w-1/2">
                    <button type="button" data-collapse-toggle="navbar-search" aria-controls="navbar-search" aria-expanded="false" class="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1">
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                        <span class="sr-only">Search serial Number</span>
                    </button>
                    <div class="relative hidden md:block">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span class="sr-only">Search icon</span>
                        </div>
                        <input type="text" id="search-navbar" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search serial number" />
                    </div>
                </div>



                <div className="w-full mt-5 p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Previous Search Result</h1>

                        </div>

                    </div>

                    <div className="relative overflow-x-auto  sm:rounded-lg mt-5">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Serial Number
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Batch Number
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Date & Time
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Updated By
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Allocated to
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    scratchcards.map((scratchcard) => (
                                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                {scratchcard.barcodeFront}
                                            </th>
                                            <td className="px-6 py-4">{scratchcard.barcodeBack}</td>
                                            <td className="px-6 py-4">{formatDate(scratchcard.createdAt)}</td>
                                            <td className="px-6 py-4">Nigeria</td>
                                            <td className="px-6 py-4">David</td>
                                            <td className="px-6 py-4">{scratchcard.isVerified === true ? <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-3/4 text-center">Active</p> : <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-3/4 text-center">Inactive</p>}</td>


                                        </tr>
                                    ))
                                }


                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Searchcard;