import React, { useState, useEffect } from "react";
import ApexCharts from "apexcharts";
import axios from "axios";

const Colchart = (id) => {

  const token = sessionStorage.getItem("bearer_token");
  const auth_token = sessionStorage.getItem("token");


  const [month, setMonth] = useState([]);
  const [values, setValues] = useState([]);

  const [isDataFetched, setIsDataFetched] = useState(false);

  const getSales = async () => {

    try {
      const res = await axios.get(process.env.REACT_APP_API_URL+"api/sales/get-sale", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "auth_token": `${auth_token}`,
        },
      });

      const sales = res.data.sales;

      // graph data

      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      // Initialize an object to store sales by month
      const salesByMonth = {};

      // Iterate over sales data 
      

      sales.forEach((sale) => {
        // Check if the sale belongs to the target ID
        if (sale.userId === id.id || sale.agentId === id.id) {
          // Extract year and month from the createdAt field
          const date = new Date(sale.salesDate);


          const monthKey = `${monthNames[date.getMonth()]}-${date.getFullYear()}`;

          // Add the totalSalesValue to the corresponding month
          salesByMonth[monthKey] = (salesByMonth[monthKey] || 0) + sale.totalSalesValue;
        }
      });

      // Convert salesByMonth object into an array of { month, totalSalesValue }
      const result = Object.entries(salesByMonth).map(([month, totalSalesValue]) => ({
        month,
        totalSalesValue,
      }));
      
      const newresult = result.sort((a, b) => {
        const aMonth = monthNames.indexOf(a.month.split("-")[0]);
        const bMonth = monthNames.indexOf(b.month.split("-")[0]);
        return aMonth - bMonth;
      });
      
      // Extract into separate arrays
      const monthArray = newresult.map((entry) => entry.month);
      const valuesArray = newresult.map((entry) => entry.totalSalesValue);

      setMonth(monthArray);
      setValues(valuesArray);
      setIsDataFetched(true);
      

    } catch (err) {
      console.error("Error in getSales:", err);
    }
  };

  useEffect(() => {
    getSales();
    const options = {
      series: [
        {
          name: "Sales",
          data: values,
        },
      ],
      chart: {
        type: "bar",
        height: 400,
        toolbar: {
          show: false,
        },
        fontFamily: "Inter, sans-serif",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          endingShape: "rounded", // Makes the bars rounded at the top
        },
      },
      xaxis: {
        categories: month,
        labels: {
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
          },
          formatter: function (value) {
            return "₦" + value; // Example: 5K, 10K
          },
        },
      },
      tooltip: {
        enabled: true,
        theme: "light",
        y: {
          formatter: function (value) {
            return `₦${value.toLocaleString()}`; // Format tooltip value with currency
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#ADFA1D"],
      grid: {
        borderColor: "#E5E7EB",
        strokeDashArray: 5,
      },
    };


    // Ensure the chart is only initialized if the DOM is ready
    const chartElement = document.getElementById("column-chart");
    if (chartElement) {
      const chart = new ApexCharts(chartElement, options);
      chart.render();

      // Clean up the chart when the component unmounts
      return () => {
        chart.destroy();
      };
    }
  }, [isDataFetched]); // Empty dependency array ensures this runs only once after the initial render

  return <div id="column-chart"></div>;
};


export default Colchart;