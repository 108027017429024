import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "flowbite";

const Batches = () => {


    const [refreshKey, setRefreshKey] = useState(0);
    const [batches, setBatches] = useState([]);

    const [file, setFile] = useState(null);
    
    

    const [batchId, setBatchId] = useState('');


    const userData = JSON.parse(sessionStorage.getItem("user_data"));

    const token = sessionStorage.getItem("bearer_token");
    const auth_token = sessionStorage.getItem("token");


    useEffect(() => {

        // Initialize the modal on component mount
        const createElement = document.getElementById("popup-modal");
        if (createElement) {
            const create_modal = new Modal(createElement);
            create_modal.hide(); // Ensure the modal is initially hidden
        }
    }, []);

    const getSuperagentname = async (id) => {
        try {
            const res = await axios.get(process.env.REACT_APP_API_URL+`api/user/get-user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            return res.data.user.name


        } catch (err) {
            if (err.response.status === 400) {
                alert(err.response.data);
            }
        }
    }

    const getSuperadminname = async (id) => {
        console.log(id);

        try {
            const res = await axios.get(process.env.REACT_APP_API_URL+`api/admin-users/get-admin-user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });

            return res.data.adminUser.name;



        } catch (err) {
            if (err.response.status === 400) {
                alert(err.response.data);
            }
        }
    }

    const processBatches = async (batches) => {
        const processedBatches = await Promise.all(
            batches.map(async (batch) => {
                try {
                    // Fetch names for updatedBy and allocatedTo concurrently
                    const [updatedByName, allocatedToName] = await Promise.all([
                        getSuperadminname(batch.updatedBy), // Fetch name for updatedBy
                        getSuperagentname(batch.allocatedTo), // Fetch name for allocatedTo
                    ]);

                    // Return the batch with added name fields
                    return {
                        ...batch,
                        updatedByName,
                        allocatedToName,
                    };
                } catch (error) {
                    console.error("Error processing batch:", batch.id, error);
                    return batch; // Return the original batch if an error occurs
                }
            })
        );

        return processedBatches;
    };

    useEffect(() => {
        const getBatch = async (e) => {

            try {
                const res = await axios.get(process.env.REACT_APP_API_URL+"api/batch/get-batches", {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "auth_token": `${auth_token}`,
                    },
                });

                const processedBatches = await processBatches(res.data.batches);


                setBatches(processedBatches);


            } catch (err) {
                if (err.response.status === 400) {
                    alert(err.response.data);
                }
            }
        }
        getBatch();


    }, [refreshKey]);

    const formatDate = (dateString) => {
        const date = new Date(dateString.replace(" ", "T")); // Replace space with T for compatibility
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const time = date.toLocaleTimeString('en-US', { hour12: false });
        return `${formattedDate} ${time}`;
    };


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleIdChange = (event) => {
        
        
        setBatchId(event.target.value); // Update ID from input field
      };

    const handleUpload = async () => {
        if (!file) {
            alert("Please select a file.")
            return;
        }
        if (!batchId) {
            alert("Please select a id.")
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userData.id)
        formData.append('batchId', batchId)
        
        
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL+'api/scratch-upload/upload-scratchcards', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${token}`,
                    "auth_token": `${auth_token}`,
                },
            });
            alert(response.data.message);
            
        } catch (err) {
            
                alert(err.response.data);

                console.error('Error uploading file:', err);
                
            
        }
    }

    return (
        <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-20 p-4 sm:ml-64">
                <ul className="flex md:w-1/2 mt-5 text-md text-black bg-[#F1F5F9]  rounded-lg overflow-hidden shadow-sm">
                    <li className="flex-1">
                        <a
                            href="/add-batch"
                            className="block m-1 p-2 text-md text-center bg-white text-black rounded-lg shadow-sm"
                            aria-current="page"
                        >
                            Batches
                        </a>
                    </li>
                    <li className="flex-1">
                        <a
                            href="/track"
                            className="block m-1 p-2 text-center text-gray-500 hover:text-black"
                        >
                            Add Allocation
                        </a>
                    </li>
                </ul>

                <div className="md:flex justify-between">

                    <button type="button" 
                    onClick={() => {
                        const createElement = document.getElementById("popup-modal");
                        if (createElement) {
                            const create_modal = new Modal(createElement);
                            create_modal.show();
                        }
                    }}
                    className="flex text-white bg-black rounded-full px-5 py-2 my-5">Add a new batch</button>


                    <div id="date-range-picker" date-rangepicker="" className="mb-5 md:mb-0 flex items-center">
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg
                                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                </svg>
                            </div>
                            <input
                                id="datepicker-range-start"
                                name="start"
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Select date start"
                            />
                        </div>
                        <span className="mx-4 text-gray-500">to</span>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg
                                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                </svg>
                            </div>
                            <input
                                id="datepicker-range-end"
                                name="end"
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Select date end"
                            />
                        </div>
                    </div>
                </div>

                <div className="w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">

                    <div className="flex justify-start">
                        <div className="flex flex-col text-left">
                            <h1 className="text-xl text-black font-bold">Batches</h1>
                            <h1 className="text-md text-gray-400">Add new lottery batches</h1>
                        </div>

                    </div>

                    <div className="relative overflow-x-auto  sm:rounded-lg mt-5">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Batch
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Date & Time
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Updated By
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                {
                                    batches.map((batch) => (
                                        <tr key={batch.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                {batch.batch}
                                            </th>
                                            <td className="px-6 py-4">{formatDate(batch.date)}</td>
                                            <td className="px-6 py-4">{batch.updatedByName}</td>
                                            <td className="px-6 py-4">{batch.allocatedToName}</td>
                                            <td className="px-6 py-4">
                                                {batch.status === true ? <p className="bg-[#DEF7EC] text-sm text-[#03543F] px-3 py-1 rounded-full md:w-3/4 text-center">Active</p> : <p className="bg-[#FBD5D5] text-sm text-red-500 px-3 py-1 rounded-full md:w-1/2 text-center">Inactive</p>}

                                            </td>


                                        </tr>
                                    ))
                                }
                                
                            </tbody>
                        </table>
                    </div>

                    <div
                        id="popup-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={() => {
                                        const createElement = document.getElementById("popup-modal");
                                        if (createElement) {
                                            const create_modal = new Modal(createElement);
                                            create_modal.hide();
                                        }
                                    }}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Add a batch</h2>
                                        <form onSubmit={handleUpload}>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="col-span-3">
                                                    <div className="flex items-center justify-center w-full">
                                                        <label
                                                            htmlFor="dropzone-file"
                                                            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                        >
                                                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                                <svg
                                                                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                                                    aria-hidden="true"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 20 16"
                                                                >
                                                                    <path
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth={2}
                                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                                    />
                                                                </svg>

                                                                <p className="text-md text-black dark:text-gray-400">
                                                                    Drag /Drop or upload .csv file here
                                                                </p>
                                                            </div>
                                                            <input id="dropzone-file" type="file" onChange={handleFileChange} className="hidden" />
                                                        </label>
                                                    </div>

                                                </div>


                                                <div className="md:col-span-1 flex items-center ">
                                                    <label className="block mb-1 font-medium">Batch</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <select
                                                        id="batchId"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                        value={batchId}
                                                        onChange={handleIdChange}
                                                        required
                                                    >
                                                        <option value="">--Select a Batch--</option>
                                                        {
                                                            batches.map((batch) => (
                                                                <option key={batch.id} value={batch.id}>{batch.batch}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>

                                            </div>
                                            

                                            <div className="mt-5 flex justify-end">



                                                <div>

                                                    <button
                                                        
                                                        type="submit"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Upload
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            const createElement = document.getElementById("popup-modal");
                                                            if (createElement) {
                                                                const create_modal = new Modal(createElement);
                                                                create_modal.hide();
                                                            }
                                                        }}
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    {/* edit modal */}
                    {/* <div
                        id="edit-modal"
                        tabIndex={-1}
                        className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                    >
                        <div className="relative p-4 w-full max-w-4xl max-h-[60%]">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <button
                                    type="button"
                                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="edit-modal"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-4  text-center">
                                    <div className=" p-6 bg-white shadow rounded-lg">
                                        <h2 className="text-2xl font-bold text-center mb-6">Edit a batch</h2>
                                        <form>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-left">

                                                <div className="md:col-span-1 flex items-center ">
                                                    <label className="block mb-1 font-medium">Uploaded By</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <select className="w-full p-2 border border-gray-300 rounded">
                                                        <option>User 1</option>
                                                        <option>User 2</option>
                                                        <option>User 3</option>
                                                    </select>
                                                </div>

                                                <div className="md:col-span-1 flex items-center ">
                                                    <label className="block mb-1 font-medium">Document</label>
                                                </div>
                                                <div className="md:col-span-2">
                                                    <select className="w-1/2 p-2 border border-gray-300 rounded">
                                                        <option>User 1</option>
                                                        <option>User 2</option>
                                                        <option>User 3</option>
                                                    </select>
                                                    <button className="ml-1 bg-red-600 text-md text-white px-5 py-2 rounded-full">Remove</button>
                                                </div>

                                            </div>


                                            <div className="mt-5 flex justify-end">
                                                <div>

                                                    <button
                                                        data-modal-hide="edit-modal"
                                                        type="button"
                                                        className="text-white bg-black rounded-full text-sm inline-flex items-center px-5 py-2.5 text-center"
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        data-modal-hide="edit-modal"
                                                        type="button"
                                                        className="py-2.5 px-5 ms-3 text-sm font-medium text-black focus:outline-none bg-white rounded-full border border-black hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>


            </div>
        </div>
    )
}

export default Batches;